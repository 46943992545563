import "../src/styles/pages/layout.css"

function NotFound() {

    return(
        <div className="layout">
            <h1>Page not found</h1>
        </div>
    )
}

export default NotFound;