import { createBrowserRouter } from "react-router-dom";
import LandingPageLayout from "./components/landingPage";
import Home from "./pages/Home";
import NotFound from "./Not-Found";
import ClientLoaginPage from "./pages/auth/client/ClientLoginPage";
import AgentLogin from "./pages/auth/agent/AgentLoginPage";
import SignUpPage from "./pages/auth/SignUpPage";
import TourPackes from "./pages/TourPackges";
import TourDetails from "./pages/TourDetails";
import About from "./pages/About";
import ProfilePage from "./pages/ProfilePage";
import AdminPage from "./pages/admin/AdminPage";
// import ProtectedRoute from "./ProtectedRoute";
import AdminLoginPage from "./pages/auth/admin/AdminLogin";
import { UserProvider } from "./context/UserAuthContext";
import { AdminAuthProvider } from "./context/AdminAuthContext";


const router = createBrowserRouter([
    {
        path: '/',
        element: <LandingPageLayout />,
        children: [
            {
                path: "/",
                element: (
                    <UserProvider>
                        <Home />
                    </UserProvider>
                )
            },
            {
                path: "*",
                element: <NotFound />
            },
            {
                path: "/client-login",
                element: 
                (
                    <UserProvider>
                       <ClientLoaginPage />
                    </UserProvider>
                )
            },
            {
                path: "/agent-login",
                element: (
                    <UserProvider>
                        <AgentLogin />
                    </UserProvider>
                )
            },
            {
                path: "/signup",
                element: (
                    <UserProvider>
                       <SignUpPage />
                    </UserProvider>
                )
            },
            {
                path: "/tour",
                element: (
                    <UserProvider>
                       <TourPackes />
                    </UserProvider>
                )
            },
            {
                path: "/group-tour",
                element: (
                    <UserProvider>
                        <TourPackes />
                    </UserProvider>
                )
            },
            {
                path: "/tour/:id",
                element:(
                    <UserProvider>
                        <TourDetails />
                    </UserProvider>
                ) 
            },
            {
                path: "/about",
                element: (
                    <UserProvider>
                        <About />
                    </UserProvider>
                )
            },
            {
                path: "/profile",
                element: (
                    <UserProvider>
                        <ProfilePage />
                    </UserProvider>
                )
            },
            {
                path: "/admin-dashboard",
                element: (
                    <AdminAuthProvider>
                        <AdminPage />
                    </AdminAuthProvider>
                )
            },
            {
                path: "/admin-login",
                element: (
                    <AdminAuthProvider>
                    <AdminLoginPage />
                    </AdminAuthProvider>
                )
            }
        ]
    }
]);

export default router;