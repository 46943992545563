import React from "react";
import '../styles/components/loadingSpiner.css'

function LoadingSpinner() {

    return (
        <>
        <div className="loader-container">
            <div className="loader">
            </div>
        </div>
        </>
    )
}

export default LoadingSpinner;