import React, { createContext, useContext, useEffect, useState } from "react";
import { ref, get, child, query, equalTo, orderByChild } from "firebase/database";
import { signOut, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth, database } from "../firebase/firebaseConfig";

const AdminAuthContext = createContext(null);

export const useAdmin = () => useContext(AdminAuthContext);

export function AdminAuthProvider({ children }) {
    const [admin, setAdmin] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentAdmin) => {
            setAdmin(currentAdmin);
            console.log("Auth admin changed:", currentAdmin);
        });
        return () => unsubscribe();
    }, []);

    const checkEmailExists = async (email, collection) => {
        const dbRef = ref(database);
        const emailQuery = query(
            child(dbRef, collection),
            orderByChild("email"),
            equalTo(email)
        );
        const emailSnapshot = await get(emailQuery);
        return emailSnapshot.exists();
    };

    const loginAdmin = async (email, password) => {
        try {
            const adminCredential = await signInWithEmailAndPassword(auth, email, password);
            const isAdmin = await checkEmailExists(email, 'admin');
            if (!isAdmin) {
                throw new Error("No account found with this email.");
            }
            return { admin: adminCredential.user};
        } catch (error) {
            console.error("Error logging in:", error);
            throw error;
        }
    };

    const logoutAdmin = async () => {
        try {
            await signOut(auth);
            setAdmin(null);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    return (
        <AdminAuthContext.Provider value={{ admin, loginAdmin, logoutAdmin }}>
            {children}
        </AdminAuthContext.Provider>
    );
}
